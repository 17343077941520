<!-- =========================================================================================
  File Name: VerticalNavMenu.vue
  Description: Vertical NavMenu Component
  Component Name: VerticalNavMenu
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <div class="parentx">
    <vs-sidebar
      class="v-nav-menu items-no-padding"
      v-model="isVerticalNavMenuActive"
      ref="verticalNavMenu"
      default-index="-1"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      v-hammer:swipe="onMenuSwipe"
    >
      <div id="verticalNavMenu" @mouseenter="mouseEnter" @mouseleave="mouseLeave">
        <!-- Header -->
        <div class="header-sidebar"
             slot="header">
          <div class="vx-logo flex item-center">
            <img :src="getClubLogo"
                 class="club-logo-img"
                 alt=""
                 width="50"
                 height="50"
            >

            <span
              class="vx-logo-text"
              v-show="isMouseEnter || !reduce"
              v-if="title">
              {{ title }}
            </span>


<!--            <custom-select class="text-primary w-3/4"
                           v-show="isMouseEnter || !reduce"
                           :options="[{label: 'فروشگاه تنیس آریا', value: 1},{label: 'فروشگاه تنیس آریا2', value: 2},]"/>-->
          </div>
          <!-- /Logo -->

          <!-- Menu Buttons -->
          <div>
            <!-- Close Button -->
            <template v-if="showCloseButton">
              <feather-icon
                icon="XIcon"
                class="m-0 cursor-pointer menu-button"
                @click="$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)"/>
            </template>

            <!-- Toggle Buttons -->
            <template v-else-if="!showCloseButton && !verticalNavMenuItemsMin">
              <feather-icon
                id="btnVNavMenuMinToggler"
                class="mr-0 cursor-pointer menu-button"
                :icon="reduce ? 'CircleIcon' : 'DiscIcon'"
                svg-classes="stroke-current text-primary"
                @click="toggleReduce(!reduce)"
              />
            </template>
          </div>
          <!-- /Menu Toggle Buttons -->

          <!-- Profile Box -->
          <!--<profile-box :vertical-nav-menu-items-min="verticalNavMenuItemsMin"
                       :reduce="isMouseEnter"/>-->
          <!-- /Profile Box -->
        </div>
        <!-- /Header -->

        <!-- Header Shadow -->
        <div class="shadow-bottom" v-show="showShadowBottom"/>

        <!-- Menu Items -->
        <component
          :is="scrollbarTag"
          ref="verticalNavMenuPs"
          class="scroll-area-v-nav-menu"
          :settings="settings"
          @ps-scroll-y="psSectionScroll"
          @scroll="psSectionScroll"
          :key="$vs.rtl"
        >
          <template v-for="(item, index) in menuItemsUpdated"
                    v-if="(!item.hasOwnProperty('permission') || checkUserPermissions(item.permission, item.permissionType || 'any')) && (!isMobile || (item.hasOwnProperty('showMobile') && item.showMobile))">
            <!-- Group Header -->
            <span
              v-if="item.header && !verticalNavMenuItemsMin"
              class="navigation-header truncate"
              :key="`header-${index}`"
            >
              {{ item.header }}
            </span>
            <!-- /Group Header -->

            <template v-else-if="!item.header">
              <!-- Nav-Item -->
              <v-nav-menu-item
                v-if="!item.submenu"
                :key="`item-${index}`"
                :index="index"
                :to="item.slug !== 'external' ? item.url : null"
                :href="item.slug === 'external' ? item.url : null"
                :icon="item.icon"
                :target="item.target"
                :isDisabled="item.isDisabled"
                :showMobile="item.showMobile ? item.showMobile : false"
                :feather-icon="item.hasOwnProperty('featherIcon') ? item.featherIcon : true"
                :slug="item.slug"
              >
                <span v-show="!verticalNavMenuItemsMin" class="truncate">{{
                    $t(item.i18n) || item.name
                  }}</span>
                <vs-chip
                  class="ml-auto"
                  :color="item.tagColor"
                  v-if="item.tag && (isMouseEnter || !reduce)"
                >{{ item.tag }}
                </vs-chip
                >
              </v-nav-menu-item>

              <!-- Nav-Group -->
              <template v-else>
                <v-nav-menu-group
                  :key="`group-${index}`"
                  :openHover="openGroupHover"
                  :group="item"
                  :groupIndex="index"
                  :open="isGroupActive(item)"
                />
              </template>
              <!-- /Nav-Group -->
            </template>
          </template>
        </component>
        <!-- /Menu Items -->

<!--        <vs-divider v-show="isMouseEnter || !reduce" class="w-full m-0 mb-3"/>
        <transition name="fade">
          <div v-show="isMouseEnter || !reduce" class="financial-year-label" @click="handleShowFinancialYearClicked">
            {{ $t('navbar.dynamicFinancialYear', {start: financialYear.startYear, end: financialYear.endYear}) }}
          </div>
        </transition>-->


        <!-- insert new user prompt -->
        <vs-prompt
          class="big-prompt p-0"
          :buttons-hidden="true"
          title=""
          :active.sync="showFinancialYearPromptStatus">

          <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
            <vs-row>
              <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                {{ $t('setting.financialYear.title') }}
              </vs-col>

              <vs-spacer/>

              <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                <div @click="showFinancialYearPromptStatus = false">
                  <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                </div>
              </vs-col>
            </vs-row>
          </div>

          <div class="prompt-content p-2">
            <template>
              <keep-alive>
                <financial-year-list :in-modal="true"/>
              </keep-alive>
            </template>
          </div>

        </vs-prompt>
      </div>
    </vs-sidebar>

    <!-- Swipe Gesture -->
    <div
      v-if="!isVerticalNavMenuActive"
      class="v-nav-menu-swipe-area"
      v-hammer:swipe="onSwipeAreaSwipe"
    />
    <!-- /Swipe Gesture -->
  </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import VNavMenuGroup from './VerticalNavMenuGroup.vue'
import VNavMenuItem from './VerticalNavMenuItem.vue'

import Logo from '../Logo.vue'
import ProfileBox from '@/layouts/components/vertical-nav-menu/components/ProfileBox'
import CustomSelect from '@/components/customSelect/customSelect'
import {checkUserPermissions} from '@/assets/js/functions'
import FinancialYearList from "../../../views/admin/settings/financialYear/list/financialYearList";
import CustomIcon from "../../../components/customIcon/customIcon";

export default {
  name: 'v-nav-menu',
  components: {
    CustomIcon,
    FinancialYearList,
    CustomSelect,
    VNavMenuGroup,
    VNavMenuItem,
    VuePerfectScrollbar,
    Logo,
    ProfileBox
  },
  props: {
    logo: {type: String},
    openGroupHover: {type: Boolean, default: false},
    parent: {type: String},
    reduceNotRebound: {type: Boolean, default: true},
    navMenuItems: {type: Array, required: true},
    title: {type: String}
  },
  data: () => ({
    showFinancialYearPromptStatus: false,
    financialYear: {
      startYear: '',
      endYear: ''
    },
    clickNotClose: false, // disable close navMenu on outside click
    isMouseEnter: false,
    reduce: false, // determines if navMenu is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    touchEndX: 0,
    touchStartX: 0,
    settings: {
      // perfectScrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true
    },
    showShadowBottom: false
  }),
  computed: {
    isMobile () {
      return this.$store.state.windowWidth < this.$store.state.mobileScreen
    },
    getClubLogo () {
      const clubInfo = this.$store.state.setting.clubInfo
      if (clubInfo.club_logo) {
        return clubInfo.club_logo
      } else {
        return require('@/assets/images/no-image.png')
      }
    },
    isGroupActive () {
      return (item) => {
        const path = this.$route.fullPath
        const routeParent = this.$route.meta ? this.$route.meta.parent : undefined
        let open = false

        const func = (item) => {
          if (item.submenu) {
            item.submenu.forEach((item) => {
              if (
                item.url &&
                (path === item.url || routeParent === item.slug)
              ) {
                open = true
              } else if (item.submenu) {
                func(item)
              }
            })
          }
        }
        func(item)
        return open
      }
    },
    menuItemsUpdated () {
      const clone = this.navMenuItems.slice()

      for (const [index, item] of this.navMenuItems.entries()) {
        if (item.header && item.items.length && (index || 1)) {
          const i = clone.findIndex((ix) => ix.header === item.header)
          for (const [subIndex, subItem] of item.items.entries()) {
            clone.splice(i + 1 + subIndex, 0, subItem)
          }
        }
      }

      return clone
    },
    isVerticalNavMenuActive: {
      get () {
        return this.$store.state.isVerticalNavMenuActive
      },
      set (val) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', val)
      }
    },
    layoutType () {
      return this.$store.state.mainLayoutType
    },
    reduceButton: {
      get () {
        return this.$store.state.reduceButton
      },
      set (val) {
        this.$store.commit('TOGGLE_REDUCE_BUTTON', val)
      }
    },
    isVerticalNavMenuReduced () {
      return Boolean(this.reduce && this.reduceButton)
    },
    verticalNavMenuItemsMin () {
      return this.$store.state.verticalNavMenuItemsMin
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    },
    windowWidth () {
      return this.$store.state.windowWidth
    }
  },
  watch: {
    $route () {
      if (this.isVerticalNavMenuActive && this.showCloseButton) this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
    },
    reduce (val) {
      const verticalNavMenuWidth = val ? 'reduced' : this.$store.state.windowWidth < 1200 ? 'no-nav-menu' : 'default'
      this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)

      setTimeout(function () {
        window.dispatchEvent(new Event('resize'))
      }, 100)
    },
    layoutType () {
      this.setVerticalNavMenuWidth()
    },
    reduceButton () {
      this.setVerticalNavMenuWidth()
    },
    windowWidth () {
      this.setVerticalNavMenuWidth()
    }
  },
  methods: {
    checkUserPermissions (permission, type) {
      return checkUserPermissions(permission, type)
    },
    onMenuSwipe (event) {
      if (event.direction === 4 && this.$vs.rtl) {
        // Swipe Right
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
      } else if (event.direction === 2 && !this.$vs.rtl) {
        // Swipe Left
        if (this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = false
      }
    },
    onSwipeAreaSwipe (event) {
      if (event.direction === 4 && !this.$vs.rtl) {
        // Swipe Right
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      } else if (event.direction === 2 && this.$vs.rtl) {
        // Swipe Left
        if (!this.isVerticalNavMenuActive && this.showCloseButton) this.isVerticalNavMenuActive = true
      }
    },
    psSectionScroll () {
      const scroll_el =
        this.$refs.verticalNavMenuPs.$el || this.$refs.verticalNavMenuPs
      this.showShadowBottom = scroll_el.scrollTop > 0
    },
    mouseEnter () {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)
      this.isMouseEnter = true
    },
    mouseLeave () {
      if (this.reduce) this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', true)
      this.isMouseEnter = false
    },
    setVerticalNavMenuWidth () {
      if (this.windowWidth > this.$store.state.mobileScreen) {
        if (this.layoutType === 'vertical') {
          // Set reduce
          this.reduce = !!this.reduceButton

          // Open NavMenu
          this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

          // Set Menu Items Only Icon Mode
          const verticalNavMenuItemsMin = !!(
            this.reduceButton && !this.isMouseEnter
          )
          this.$store.commit(
            'UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN',
            verticalNavMenuItemsMin
          )

          // Menu Action buttons
          this.clickNotClose = true
          this.showCloseButton = false

          const verticalNavMenuWidth = this.isVerticalNavMenuReduced ? 'reduced' : 'default'
          this.$store.dispatch(
            'updateVerticalNavMenuWidth',
            verticalNavMenuWidth
          )

          return
        }
      }

      // Close NavMenu
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      // Reduce button
      if (this.reduceButton) this.reduce = false

      // Menu Action buttons
      this.showCloseButton = true
      this.clickNotClose = false

      // Update NavMenu Width
      this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      // Remove Only Icon in Menu
      this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)

      // if(this.layoutType === 'vertical' || (this.layoutType === 'horizontal' && this.windowWidth < 1200))
      // if (this.windowWidth < 1200) {

      //   // Close NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)

      //   // Reduce button
      //   if (this.reduceButton) this.reduce = false

      //   // Menu Action buttons
      //   this.showCloseButton = true
      //   this.clickNotClose   = false

      //   // Update NavMenu Width
      //   this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')

      //   // Remove Only Icon in Menu
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', false)

      // } else {

      //   // Set reduce
      //   this.reduce = this.reduceButton ? true : false

      //   // Open NavMenu
      //   this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)

      //   // Set Menu Items Only Icon Mode
      //   const verticalNavMenuItemsMin = (this.reduceButton && !this.isMouseEnter) ? true : false
      //   this.$store.commit('UPDATE_VERTICAL_NAV_MENU_ITEMS_MIN', verticalNavMenuItemsMin)

      //   // Menu Action buttons
      //   this.clickNotClose   = true
      //   this.showCloseButton = false

      //   const verticalNavMenuWidth   = this.isVerticalNavMenuReduced ? "reduced" : "default"
      //   this.$store.dispatch('updateVerticalNavMenuWidth', verticalNavMenuWidth)
      // }
    },
    toggleReduce (val) {
      this.reduceButton = val
      this.setVerticalNavMenuWidth()
    },
    handleTouchStart (event) {
      const touches = event.changedTouches
      this.touchStartX = touches[0].clientX
    },
    handleTouchEnd (event) {
      const touches = event.changedTouches
      this.touchEndX = touches[0].clientX
      if ((this.touchEndX - this.touchStartX) > 50) {
        // console.log(touches)
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
      }
      // console.log(this.touchStartX - this.touchEndX)
    },

    getFinancialYearInfo () {
      this.financialYear = this.$store.state.auth.user.financial_year
      if (this.financialYear) {
        this.financialYear.startYear = this.financialYear.started_at.split('/')[0]
        this.financialYear.endYear = this.financialYear.finished_at.split('/')[0]
      } else {
        this.financialYear = {
          startYear: '',
          endYear: ''
        }
      }
    },

    handleShowFinancialYearClicked () {
      if (checkUserPermissions('financial_year.switch')) {
        this.showFinancialYearPromptStatus = true
      }
    }
  },
  mounted () {
    this.setVerticalNavMenuWidth()
    this.getFinancialYearInfo()

    const el = window.document.getElementById('verticalNavMenu')
    el.addEventListener('touchstart', this.handleTouchStart, {passive: false})
    el.addEventListener('touchend', this.handleTouchEnd, {passive: false})
  },

  beforeDestroy () {
    const el = window.document.getElementById('verticalNavMenu')
    if (el) {
      el.removeEventListener('touchstart', this.handleTouchStart, false)
      el.removeEventListener('touchend', this.handleTouchEnd, false)
    }
  }
}
</script>


<style lang="scss">
@import "@/assets/scss/vuexy/components/verticalNavMenu.scss";

.header-sidebar {

  .vx-logo-text {
    font-size: 16px;
  }

  .club-logo-img {
    object-fit: contain;
  }

  .useral-custom-element-select {

    .selected {
      background-color: #131314;
      border-radius: 0;
      cursor: pointer;
      padding-left: 0.3rem;
      font-size: 1rem;
      font-weight: bold;
    }

    .items {
      border-radius: 5px;
      box-shadow: 0px 3px 10px black;
      background-color: #131314;
      z-index: 1000;

      div:first-child {
        border: none !important;
      }

      div:hover {
        background-color: #222222;
      }
    }
  }
}

.scroll-area-v-nav-menu {
  .feather-icon {
    margin-right: 0;
  }

  .truncate {
    margin-left: 13px;
  }
}
</style>
