<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon id="showNotifications" icon="BellIcon" class="cursor-pointer mt-1" :badge="unreadNotifications.length"/>

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-5 bg-primary text-white">
        <h5 class="text-white">{{ unreadNotifications.length }} {{ $t('navbar.notification.newMessage') }}</h5>
        <!--        <p class="opacity-75">پیام های سامانه</p>-->
      </div>

      <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10"
                 :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li v-for="ntf in unreadNotifications"
              :key="ntf.index"
              class="notification cursor-pointer px-4 py-4"
              @click="selectedEvent = unreadNotificationsSources[ntf.index] ,$refs.event.showEvent()">

            <div class="notification-header">
              <div class="notification-title">
                <feather-icon :icon="ntf.icon"
                              :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>

                <span class="font-medium block notification-title" :class="[`text-${ntf.category}`]">{{
                    ntf.title
                  }}</span>
              </div>
              <div class="notification-time">{{ ntf.time }}</div>
            </div>
            <div class="notification-content">
              <vue-markdown>
                {{ ntf.msg }}
              </vue-markdown>
            </div>
          </li>
        </ul>
      </component>

      <router-link :to="{name: 'profileReceivedEvents'}">
        <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer" @click="closeDropdown()">
          <span>{{ $t('navbar.notification.showAll') }}</span>
        </div>
      </router-link>
    </vs-dropdown-menu>

    <custom-event ref="event"
                  :event-data="selectedEvent"/>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {getProfileReceivedEvents} from '../../../../http/requests/users/users'
import vueMarkdown from 'vue-markdown'
import CustomEvent from '../../../../components/customEvent/customEvent'

export default {
  components: {
    CustomEvent,
    VuePerfectScrollbar,
    vueMarkdown
  },
  data () {
    return {
      selectedEvent: {},
      unreadNotifications: [
        /*{
          index    : 0,
          title    : 'New Message',
          msg      : 'Are your going to meet me tonight?',
          icon     : 'MessageSquareIcon',
          time     : this.randomDate({sec: 10}),
          category : 'primary'
        },
        { index    : 1,
          title    : 'New Order Recieved',
          msg      : 'You got new order of goods.',
          icon     : 'PackageIcon',
          time     : this.randomDate({sec: 40}),
          category : 'success'
        },
        { index    : 2,
          title    : 'Server Limit Reached!',
          msg      : 'Server have 99% CPU usage.',
          icon     : 'AlertOctagonIcon',
          time     : this.randomDate({min: 1}),
          category : 'danger'
        },
        { index    : 3,
          title    : 'New Mail From Peter',
          msg      : 'Cake sesame snaps cupcake',
          icon     : 'MailIcon',
          time     : this.randomDate({min: 6}),
          category : 'primary'
        },
        { index    : 4,
          title    : 'Bruce\'s Party',
          msg      : 'Chocolate cake oat cake tiramisu',
          icon     : 'CalendarIcon',
          time     : this.randomDate({hr: 2}),
          category : 'warning'
        }*/
      ],
      unreadNotificationsSources: [],
      iconMapper: {
        1: 'PackageIcon',
        2: 'LayersIcon',
        3: 'MessageSquareIcon',
        4: 'AlertCircleIcon'
      },
      categoryMapper: {
        1: 'success',
        2: 'secondary',
        3: 'primary',
        4: 'warning'
      },
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  computed: {
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  created () {
    this.getUnReedMessages()
  },
  methods: {
    closeDropdown () {
      document.getElementById('showNotifications').click()
    },
    getUnReedMessages () {
      getProfileReceivedEvents(1, ['seen=0']).then(response => {
        const events = response.data.data
        this.unreadNotificationsSources = events
        events.forEach((event) => {
          this.unreadNotifications.push({
            index: this.unreadNotifications.length,
            title: event.event.subject,
            msg: event.event.content,
            icon: this.iconMapper[event.event.type],
            time: event.event.created_at,
            category: this.categoryMapper[event.event.type]
          })
        })
      })
    },
    elapsedTime (startTime) {
      const x = new Date(startTime)
      const now = new Date()
      let timeDiff = now - x
      timeDiff /= 1000

      const seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      const minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      const hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      const days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      const years = timeDiff

      if (years > 0) {
        return `${years + (years > 1 ? ' Years ' : ' Year ')}ago`
      } else if (days > 0) {
        return `${days + (days > 1 ? ' Days ' : ' Day ')}ago`
      } else if (hours > 0) {
        return `${hours + (hours > 1 ? ' Hrs ' : ' Hour ')}ago`
      } else if (minutes > 0) {
        return `${minutes + (minutes > 1 ? ' Mins ' : ' Min ')}ago`
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? ' sec ago' : 'just now')
      }

      return 'Just Now'
    },
    // Method for creating dummy notification time
    randomDate ({hr, min, sec}) {
      const date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    }
  }
}

</script>

<style lang="scss">
.con-vs-dropdown--menu {
  z-index: 71000 !important;

  .scroll-area--nofications-dropdown {

    .notification {
      display: flex;
      flex-direction: column;

      .notification-header {
        display: flex;
        flex-direction: row;

        .notification-title {
          display: flex;
          flex-direction: row;
          flex-grow: 1;
        }

        .notification-time {
          font-size: 12px;
        }
      }

      .notification-content {
        margin-top: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        p, span, div {
          width: 100%;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 40px;
        }

        h1, h2, h3, h4, h5, h6, ul, li {
          display: inline-flex;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 40px;
        }
      }
    }
  }
}
</style>

